html, body, #root, .divider {
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  background-image: url("https://paralelnilisty.cz/wp-content/uploads/2020/02/background.jpg");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

header {
  max-width: 1024px;
  margin: 0 auto;
  background-color: #fff;
}

header .menu {
  display: flex;
}

header .menu a {
  display: flex;
  align-items: center;
  padding: 17.5px 15px;
  color: #505050;
  font: 700 14px/20px "Open Sans", Verdana, sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  border-right: 1px solid #eaeaea;
  border-top: 2px solid transparent;
}

header .menu a:nth-child(3n) {
  border-top-color: #009866;
}

header .menu a:nth-child(3n + 1) {
  border-top-color: #ff6b6b;
}

header .menu a:nth-child(3n + 2) {
  border-top-color: #ab47bc;
}


header .menu a:hover {
  color: #009866;
}

header .menu a svg {
  margin-right: 5px;
}

.header-body {
  background: url(https://paralelnilisty.cz/wp-content/themes/paralelnilisty/img/logo.png) no-repeat;
  height: 200px;
  min-width: 400px;
  background-repeat: no-repeat;
  background-color: #e6d7a1;
  padding: 70px 30px 0 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}


.header-right {
  font-size: 1.5em;
  display: flex;
  align-items: flex-end;
}

.header-right * {
  margin: 0
}

.header-text {
  opacity: 83%;
}

.content {
  background: #fff;
  max-width: 1024px;
  box-sizing: border-box;
  padding: 10px;
  margin: 0 auto;
  min-height: calc(100% - 260px);
  display: flex;
  align-items: center;
}

.display-flex {
  display: flex;
}

.flex-grow {
  flex-grow: 1;
}

.make-it-half {
  width: 50%;
}

.frame {
  background-color: #ddd;
  border: 0px solid #ddd;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: #333;
  font-size: 1em;
  padding: 10px 15px;
}

.btn-group-welcome {
  display: flex;
  align-items: center;
  margin-top: 3px;
}

.btn-group {
  margin-top: 0.2em;
}

button {
  background-color: #fff;
  border: 1px solid #c8c8c8;
  cursor: pointer;
  transition: background-color linear 0.2s;
  display: flex;
  align-items: center;
  font-size: 1.5em;
}

button:hover {
  background-color: #ccc;
}

.gallery-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  grid-auto-rows: 1fr;
}

@media screen and (min-width: 1000px) {
  .wrapper {
    display: flex;
  }

  .preview-column {
    margin-right: 1em;
  }
}

@media screen and (max-width: 1000px) {
  .preview-column {
    margin-bottom: 1em;
  }
}

@media screen and (max-width: 800px) {
  .header-body {
    display: block;
    padding-top: 60px;
  }

  .header-text {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .gallery-grid {
    grid-template-columns: 1fr 1fr;
  }

  header .menu a:nth-child(3n+0){
    display: none;
  }
}

@media screen and (max-width: 450px) {
  header .menu a:nth-child(2n+0){
    display: none;
  }
  header .menu a {
    flex-grow: 1;
    justify-content: center;
  }
}